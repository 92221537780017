import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {Helmet} from 'react-helmet'
import TheTeamMainContent from "../templates/the-team/the-team-page-content-template"
import TheTeamMemberContent from "../templates/the-team/the-team-member-template"
import Hero02 from "../templates/home/hero-02-template"
import HeroImgSubPage from "../templates/about-us/about-us-hero-template"

const TheTeam = () => (

  <>
    <Layout>
      <Helmet>
        <body className="inner-page" data-active-page="page-the-team" />
      </Helmet>
      <SEO title="The Team" />
      {/* <HeroImgSubPage /> */}
      <Hero02 />
      <section className="content-container">
      <TheTeamMainContent />
      <div className="team-listing-wrapper">
        <TheTeamMemberContent />
      </div>
      </section>
    </Layout>
  </>
)

export default TheTeam
