import React from "react"
import { StaticQuery, graphql } from "gatsby"
import RemarkFormatter from "../../components/remark-formatter"

const TeamMember = (props) => {
    const { data } = props
    const { edges: posts } = data.allMarkdownRemark

    return (
        <>

            {posts &&
                posts.map(({ node: post }) => (
                    <>
                        <div className="team-member-wrapper">
                            <div className="image-wrapper">
                                <img src={post.frontmatter.headshot} alt={`Photograph of ${post.frontmatter.name}`} />
                            </div>
                            <div className="details-wrapper">
                                <h2 className="name">{post.frontmatter.name}</h2>
                                <p className="job-title">{post.frontmatter.jobTitle}</p>
                                <RemarkFormatter className="introduction" content={post.frontmatter.introduction} />
                                <RemarkFormatter className="details" content={post.frontmatter.details} />
                            </div>
                        </div>

                    </>
                ))}

        </>
    )
}

export default () => <StaticQuery
    query={graphql`
        query TeamMemberQuery {
            allMarkdownRemark(
                filter: {frontmatter: 
                    {templateKey: 
                        { eq: "team-member-post" }
                    }
                }
                sort: { order: ASC, fields: [frontmatter___listOrder] }
                ) {
                edges {
                node {
                    id
                    frontmatter {
                        name
                        listOrder
                        headshot
                        jobTitle
                        introduction
                        details
                        templateKey
                    }
                }
                }
            }
        }
   `}
    render={data => (
        <TeamMember data={data} />
    )}
/>