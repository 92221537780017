import React from 'react'
import PropTypes from 'prop-types'
import HeroContent from './hero-content'

const HeroContainer = ({ id, overlay, imgDesktop, imgMobile, caption, linksTo }) => {
 
    return (

        <section
            id={id}
            className="hero-section-wrapper"
        >

            <div 
                className="hero-section-container mobile"
                style={{backgroundImage: `url(${imgMobile})`}}
            >
                <HeroContent caption={caption} linksTo={linksTo} overlay={overlay} />
            </div>

            <div 
                className="hero-section-container desktop"
                style={{backgroundImage: `url(${imgDesktop})`}}
            >
                <HeroContent caption={caption} linksTo={linksTo} overlay={overlay} />
            </div>

        </section>     
    )
}

export default HeroContainer