import React from 'react'
import HeroLink from './hero-link'
import MobileOverlay from '../../../static/img/hero-overlays/hero-overlay-triangle-navy.svg'


const HeroContent = ({overlay, caption, linksTo}) => {
 
    return (
        <>

            {overlay &&
                <img className="overlay" src={overlay} alt="" />
            }

            
            {caption &&
                <div className="caption">
                    <h2>{caption}</h2>
                    <img className="mobile-overlay" src={MobileOverlay} alt="" />
                </div> 
            }
            

            {linksTo &&
                <HeroLink linksTo={linksTo} />
            }

        </>
        
    )
}

export default HeroContent