import React from 'react'
import DownLinkIcon from '../../images/graphic-elements/down-arrow-white.svg'

const HeroLink = ({linksTo}) => {
 
    return (
        <div className="nav-down-container">
            <a className="nav-down-link" href={`#${linksTo}`}>
                <img src={DownLinkIcon} alt="" />
                <span className="sr-only">Next Section</span>
            </a>
        </div>
    )
}

export default HeroLink